import React from "react"
import { Link, graphql } from "gatsby"

import FJudgeToolbar from "../components/toolbar.js"
// import Layout from "../components/layout"
import SEO from "../components/seo"

import '../css/global.css'

import wapner from "../../content/assets/judges/judge_wapner.jpg"
import reinhold from "../../content/assets/judges/judge_reinhold.jpg"
import judy from "../../content/assets/judges/judge_judy.jpg"
// import taylor from "../../content/assets/judge_taylor.jpg"
import smails from "../../content/assets/judges/judge_smails.jpg"
import haller from "../../content/assets/judges/judge_haller.jpg"
import stone from "../../content/assets/judges/judge_stone.jpg"


// we need this function to handle scoring ties, GraphQL can't handle it
function sortLeaders(edges) {
  const leaders = edges.sort((a,b) => b.node.rawscore - a.node.rawscore).reduce((acc, curr, idx) => {
    // console.log(curr)
    const tied = (curr.node.rawscore === acc.lastScore)
    // console.log( `${curr.node.score} - ${acc.lastScore} - ${tied}` )
    if (tied) {
      curr.node.position = `${acc.position}-T`
      acc.newList[idx - 1].node.position = `${acc.position}-T`
      acc.newList.push(curr)
      // console.log('tied')
    } else {
      // console.log(curr)
      acc.position++
      acc.lastScore = curr.node.rawscore
      if (acc.position <= 70) {
        curr.node.position = `${acc.position}`
        acc.newList.push(curr)
      }
    }
    // console.log('returning acc')
    return acc
  }, { newList:[] , lastScore: 9999 , position:0 } )
  // console.log(leaders.newList)
  return leaders.newList.filter(i => parseInt(i.node.position.substr(0,1)) <= 3)
}


class HomePage extends React.Component {
  render() {
    const { data } = this.props
    // const siteTitle = data.site.siteMetadata.title
    const posts = data.allYaml.edges
    const siteLeaders2020 = sortLeaders( data.rank20.edges )
    const siteLeaders2019 = sortLeaders( data.rank19.edges )

    const maxWeek = Math.max(...posts.map(p => p.node.meta.week))

    return (
      // <Layout>
      <div className="flex flex-col min-h-screen">
        <SEO title="The Fantasy Judge | Reviewing fantasy football experts" />
        <FJudgeToolbar />
        <main className="flex-1 sm:max-w-full mx-4">
          <section className="sm:flex sm:flex-row font-lusi font-bold my-8 leading-tight">
            <div className="hidden sm:block home-col w-15pct items-start">
              <div className="mb-2"><img className="h-32 inline" src={wapner} alt="Judge Wapner" /></div>
              <div className="mb-2"><img className="h-32 inline" src={reinhold} alt="Judge Reinhold" /></div>
              <div className="mb-2"><img className="h-32 inline" src={judy} alt="Judge Judy" /></div>
            </div>
            <div className="home-col sm:w-70pct">
              <div className="text-3xl text-center">
                <span className="inline-block">You rely on solid, accurate, insightful</span><br /> 
                <span className="inline-block text-green-700">&nbsp;fantasy football advice</span><br />
                <span className="inline-block">&nbsp;to win championships.</span>
              </div>
              <div className="text-3xl text-center my-8">But who can you trust?</div>
              <div className="text-3xl text-center my-8">
                <span className="inline-block">
                  <span className="inline-block">&nbsp;Who publishes </span>
                  <span className="inline-block">&nbsp;the most reliable info?</span>
                </span>
                <span className="inline-block">
                  <span className="inline-block">&nbsp;How can you avoid</span>
                  <span className="inline-block">&nbsp;following disastrous advice?</span>
                </span>
              </div>
              <div className="text-4xl sm:text-5xl text-center my-12"> 
                <span className="inline-block">The Fantasy Judge</span>
                <span className="inline-block">&nbsp;knows!</span>
              </div>
            </div>
            <div className="hidden sm:block home-col w-15pct content-end">
              <div className="mb-2 text-right"><img className="h-32 inline" src={haller} alt="Judge Haller" /></div>
              <div className="mb-2 text-right"><img className="h-32 inline" src={smails} alt="Judge Smails" /></div>
              <div className="mb-2 text-right"><img className="h-32 inline" src={stone} alt="Judge Stone" /></div>
              <div className="text-right text-sm font-sans font-normal"><Link to="/help/judges">judge references</Link></div>
            </div>

            {/* <!-- judge images on mobile --> */}
            <div className="sm:hidden flex flex-row">
              <div className="width-30pct"><img className="inline" src={wapner} alt="Judge Wapner" /></div>
              <div className="ml-2 width-30pct"><img className="inline" src={reinhold} alt="Judge Reinhold" /></div>
              <div className="ml-2 width-30pct"><img className="inline" src={judy} alt="Judge Judy" /></div>
            </div>
            <div className="sm:hidden flex flex-row mt-2">
              <div className="width-30pct"><img className="inline" src={haller} alt="Judge Haller" /></div>
              <div className="ml-2 width-30pct"><img className="inline" src={smails} alt="Judge Smails" /></div>
              <div className="ml-2 width-30pct"><img className="inline" src={stone} alt="Judge Stone" /></div>
            </div>
            <div className="sm:hidden text-right text-sm font-sans font-normal"><Link to="/help/judges">judge references</Link></div>
          </section>
          <hr className="p-0 border-double border-t-4 border-yellow-900 sm:mx-48" />      
          <section className="sm:max-w-80pct mx-auto flex flex-col items-center my-12 p-4 bg-red-300 text-xl sm:text-2xl font-monda">
            <div className="text-center font-bold">
              Current leading football experts (through Week {maxWeek}):
            </div>
            {/* <div className="table border-collapse md:max-w-90pct mx-auto">
              {c.experts.map(e => {
                return(
                  <div className="table-row border-b border-gray-500">
                    <div className="table-cell font-bold pr-2 md:pr-6 py-1">{siteXform[e.site] || e.site}</div>
                    <div className="table-cell">{e.ev}</div>
                  </div>
                )
              })}
            </div> */}
            <div className="my-4 table border-collapse">
              {siteLeaders2020.map(({ node }, idx) => {
                return (
                  <div className="table-row border-b border-gray-500" key={idx}>
                    <div className="table-cell text-center font-bold md:pr-6 py-1 px-2">{node.position}.</div>
                    <div className="table-cell py-1 px-2">{node.site}</div>
                  </div>
                )
              })}
            </div>
            <Link to="/scores">see full scoreboard</Link>

          </section>

          <section className="sm:max-w-80pct mx-auto flex flex-col items-center my-12 p-4 bg-green-300 text-xl sm:text-2xl font-monda">
            <div className="text-center font-bold">
              2019 football champions:
            </div>
            <div className="my-4 table border-collapse">
              {siteLeaders2019.map(({ node }, idx) => {
                return (
                  <div className="table-row border-b border-gray-500" key={idx}>
                    <div className="table-cell text-center font-bold md:pr-6 py-1 px-2">{node.position}.</div>
                    <div className="table-cell py-1 px-2">{node.site}</div>
                  </div>
                )
              })}
            </div>
            <Link to="/scores/2019">see final 2019 scoreboard</Link>

          </section>

          <hr className="p-0 border-double border-t-4 border-yellow-900 sm:mx-48" />      
          <section className="sm:max-w-80pct mx-auto font-lusi my-12 leading-snug">
            <div className="font-normal text-2xl text-justify">The Fantasy Judge follows prominent fantasy sports experts, from major networks to independent advisors, seeking out the best, most reliable forecasters. Every week a "case" involving key fantasy players and scenarios is decided by the Judge, who reviews the experts' rankings and content to determine who passed along reliable advice to their readers and listeners. Ultimately he passes down a "verdict" according to who successfully anticipated the biggest impact players, and who made fantasy recommendations which failed to meet expectations.</div>
          </section>
          <hr className="p-0 border-double border-t-4 border-yellow-900 sm:mx-48" />      
          <section className="sm:max-w-80pct mx-auto my-12">
            <div className="font-lusi font-bold text-3xl text-center mb-8">Latest Fantasy Cases</div>
              {posts.map(({ node }) => {
                const title = `The Judge's Verdict: ${node.meta.sport} ${node.meta.season} Week ${node.meta.week}`
                return (
                  <article key={node.fields.slug} className="font-typ mb-8">
                    <header className="flex md:flex-row md:justify-between md:items-baseline">
                      <div className="text-2xl hover:text-blue-500">
                        <Link to={`${node.fields.slug}`}>{title}</Link>
                      </div>
                      <div className="text-sm text-right">{node.meta.date}</div>
                    </header>
                    <section>
                      <div className="px-6 whitespace-pre-line"
                        dangerouslySetInnerHTML={{
                          __html: `<span className="underline">CASE SUMMARY</span>: ${node.content.preview || node.content.argument}`
                        }}
                      />
                    </section>
                  </article>
                )
              })}
            <div className="font-lusi font-bold text-2xl text-center">
              <Link to="/verdicts">see All Cases &amp; Verdicts</Link></div>
          </section>
        </main>
        <footer className="mt-8">
          <div className="font-rsone text-lg bg-yellow-900 text-yellow-200 text-center w-full py-4">
            The Fantasy Judge © {new Date().getFullYear()}
          </div>
        </footer>
      </div>        
      // </Layout>
    )
  }
}

export default HomePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    rank20: allGSheetsSite(
        filter: {meta: {sport: {eq: "football"}, year: {eq: 2020}}}, 
        limit: 5, 
        sort: {fields: rawscore, order: DESC}) {
      edges {
        node {
          site
          score
          rawscore
        }
      }
    }
    rank19: allGSheetsSite(
        filter: {meta: {sport: {eq: "football"}, year: {eq: 2019}}}, 
        limit: 5, 
        sort: {fields: rawscore, order: DESC}) {
      edges {
        node {
          site
          score
          rawscore
        }
      }
    }    
    allYaml(sort: {fields: meta___date, order: DESC}, limit: 3) {
      edges {
        node {
          content {
            argument
          }
          meta {
            week
            sport
            season
            date(formatString: "MMMM D, YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
